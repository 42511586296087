import { useEffect, useState, lazy, Suspense } from 'react';
import setColorsFromThemeJsonTS from './app/layout/css/theme';
import { BrowserRouter as Router, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/store/configureStore";
import { setApplicationSettings, loadEnterprise, setEnterpriseDetail } from "./modules/common/settingsSlice";
import * as searchActions from "./modules/facilities/components/search/searchSlice";
import { PreLoader } from './modules/loader/Loader';
import { setFacilityPreCartDetail } from './modules/facilities/components/facilityPreCart/facilityPreCartSlice';
const RouteComponent = lazy(() => import('./modules/Route'));


const LocationWrapper = ({ children }: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const search = useAppSelector(state => state.search);
  const navigationType = useNavigationType();
  const common = useAppSelector((state) => state.common);
  const authentication = useAppSelector((state) => state.authentication);
  const removePreCartData = () => {
    if (!location.pathname.includes("SelectReservationPreCart") && !location.pathname.includes("park/")) {
      localStorage.removeItem("preCartData");
      localStorage.removeItem("vehicleData");
      localStorage.removeItem("dynamicVehicleData");
      dispatch(setFacilityPreCartDetail(null));
    }
    if (location.pathname.includes("park/") || location.pathname.includes("results")) {
    } else {
      localStorage.removeItem("tempPreCartData");
    }

    if (location.pathname.includes("park/") || location.pathname.includes("results") || location.pathname.includes("SelectReservationPreCart")) {
    } else {
      let searchData = {
        startDate: location.pathname.includes("ShoppingCart") ? new Date(sessionStorage.getItem("selectedStartdate") || "") : null,
        endDate: location.pathname.includes("ShoppingCart") ? new Date(sessionStorage.getItem("selectedEnddate") || "") : null,
        nights: location.pathname.includes("ShoppingCart") ? (sessionStorage.getItem("selectedNights") ? sessionStorage.getItem("selectedNights") : 0) : 0,
        unitCategoryId: null,
        sleepingUnitId: null,
        minVehicleLength: null,
        unitTypesGroupIds: [],
        isADA: false,
        amenitiesIds: [],
        isAdditionalFilterShow: false,
        sort: common.applicationSettings?.defaultParkListSort,
        unitSortBy: common.applicationSettings?.defaultUnitSort,
        placeName: location.pathname.includes("ShoppingCart") ? search.selectedPlace?.Name : ""
      };
      dispatch(searchActions.setSearchData(searchData));
      if(!location.pathname.includes("ShoppingCart"))
      {
        dispatch(searchActions.setSelectedPlace(null));
      }
    }
  }
  const removeSignPreCartData = () => {
    if (!location.pathname.includes("SelectSignUpPreCart") && !location.pathname.includes("Details") && !location.pathname.includes("TellAFriend") &&
      (localStorage.getItem("addDependentFromActivitySignUpPrecart") === "false" || localStorage.getItem("addDependentFromActivitySignUpPrecart") === "")) {
      localStorage.removeItem("ActivitySignUp");
      localStorage.removeItem("ActivitySessionID");
    }
  }
   

  useEffect(() => {
    if (authentication.isLoggedIn && location.pathname.includes('login/callback')) {  
      // get cookie lastUrl     
      const lastUrl = localStorage.getItem('login_redirect_url');
      if (lastUrl) {
        navigate(lastUrl, { replace: true });
        localStorage.removeItem('login_redirect_url');
      }   

      if (navigationType === 'POP') {
        navigate('/', { replace: true });
        window.location.reload();
      }   

    }
  }, [navigationType, location.pathname, navigate]);

  useEffect(() => {
    removePreCartData();
    removeSignPreCartData();
  }, [location.pathname]);
  return children
}

function App() {
  const [baseName, setBaseName] = useState("");
  const dispatch = useAppDispatch();
  const common = useAppSelector(state => state.common);

  useEffect(() => {
    fetch(`config.json`)
      .then(response => response.json())
      .then(data => {
        loadEnterprise((enterprise: any) => {
          if (enterprise) {
            setColorsFromThemeJsonTS(enterprise.enterpriseName);
            data.installIdentity = enterprise.installIdentity;
            data.webStoreId = enterprise.webStoreId;
            data.imageBaseUrl = enterprise.cdnURL ? enterprise.cdnURL : "";
            dispatch(setApplicationSettings(data));
            dispatch(setEnterpriseDetail(enterprise));
          }
        });
      })
    setTimeout(() => {
      setBaseName((window as any).instanceName);
    }, 0);
  }, []);

  return (
    <Suspense fallback={<PreLoader isPreLoaderShow={true} />}>
      {/* <ErrorBoundary> */}
      <Router basename={baseName}>
        <LocationWrapper>
          {common.applicationSettings && common.enterpriseDetail ? <RouteComponent /> :
            <PreLoader isPreLoaderShow={true} />}
        </LocationWrapper>
      </Router>
      {/* </ErrorBoundary> */}
    </Suspense>
  );
}

export default App;

